<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between">
        <div>
          <h2>{{ $t('complaints.complaint_details.title') }} {{ detailsData && detailsData.id }}</h2>
        </div>
        <div>
          <b-button
            v-if="$can('list', 'complaint')"
            variant="outline-primary"
            @click="$router.push({ name: 'plainte-list' })"
          >
            <i class="ri-arrow-left-line mr-25 align-middle" />
            <span class="align-middle">{{ $t('button.back_to_list') }}</span>
          </b-button>
        </div>
      </div>
    </b-card>

    <b-row>
      <!-- Les détails de la plainte -->
      <b-col lg="6">
        <b-card>
          <b-card-header>
            <h4><strong>{{ $t('complaints.complaint_details.informations.title') }} : </strong>{{ detailsData && detailsData.id }}</h4>
          </b-card-header>
          <hr>
          <b-card-body>
            <p><strong>{{ $t('complaints.complaint_details.informations.company') }} : </strong>{{ detailsData && detailsData.profile && detailsData.profile.nom_etablissement }}</p>
            <p><strong>{{ $t('complaints.complaint_details.informations.sector') }} : </strong>{{ detailsData && detailsData.profile.secteur.title }}</p>
            <p><strong>{{ $t('complaints.complaint_details.informations.lastname') }} : </strong>{{ detailsData && detailsData.lastname }}</p>
            <p><strong>{{ $t('complaints.complaint_details.informations.firstname') }} : </strong>{{ detailsData && detailsData.firstname }}</p>
            <p><strong>{{ $t('complaints.complaint_details.informations.phone') }} : </strong>{{ detailsData && detailsData.phone_number }}</p>
            <p><strong>{{ $t('complaints.complaint_details.informations.mail') }} : </strong>{{ detailsData && detailsData.email }}</p>
            <p><strong>{{ $t('complaints.complaint_details.informations.object') }} : </strong>{{ detailsData && detailsData.object }}</p>
            <p><strong>{{ $t('complaints.complaint_details.informations.message') }} : </strong>{{ detailsData && detailsData.message }}</p>
            <p><strong>{{ $t('complaints.complaint_details.informations.date') }} : </strong>{{ detailsData && detailsData.created_at }}</p>
            <hr>
            <p><strong>{{ $t('complaints.complaint_details.informations.attachment') }}</strong></p>
            <DownloadFile :documents="detailsData && detailsData.media" />
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Le bouton de traitement de la plainte -->
      <b-col lg="6">
        <b-card v-if="shouldDisplayTreatmentCard">
          <b-card-header>
            <h4>
              <strong>{{ $t('complaints.complaint_details.treatment.title') }}</strong>
            </h4>
          </b-card-header>
          <hr>
          <b-card-body>
            <b-button
              v-if="$can('treat', 'complaint')"
              v-b-modal.modal-reponse-ticket
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-25"
              @click="traiterPlainte()"
            >
              <span>{{ $t('complaints.complaint_details.treatment.button_treat') }}</span>
            </b-button>
          </b-card-body>
        </b-card>

        <!-- La timeline de la plainte -->
        <b-card>
          <b-card-header>
            <h4><strong>{{ $t('complaints.complaint_details.treatment_timeline.title') }}</strong></h4>
          </b-card-header>
          <hr>
          <b-card-body>
            <TreatmentTimeline :status-data="detailsData" />
          </b-card-body>
        </b-card>
      </b-col>
      <!-- Modal de traitement de la plainte -->
      <b-modal
        :ref="modalTraitePlainteRef"
        :title="$t('complaints.complaint_details.modal_treatment.title')"
        modal-class="modal-primary"
        no-close-on-backdrop
        hide-footer
        @hidden="resetModalData"
      >
        <!-- @ok="treated"
        @hidden="resetModalData" -->
        <validation-observer ref="validationForm">
          <b-card-text>
            <b-form-group
              :label="$t('complaints.complaint_details.modal_treatment.label_status')"
              label-for="etat"
              label-cols-md="12"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <v-select
                  id="etat"
                  v-model="plainteObject.object"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="listStatus"
                  :reduce="options=>options.alias"
                  label="label"
                  :placeholder="$t('complaints.complaint_details.modal_treatment.label_status_placeholder')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('complaints.complaint_details.modal_treatment.label_comment')"
              label-for="textarea-observation"
              label-cols-md="12"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <b-form-textarea
                  id="textarea-observation"
                  v-model="plainteObject.observation"
                  :placeholder="$t('complaints.complaint_details.modal_treatment.label_comment_placeholder')"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-checkbox
              v-model="isSelected"
              class="mb-1"
            >
              {{ $t('complaints.complaint_details.modal_treatment.label_message') }}
            </b-form-checkbox>
            <b-form-textarea
              v-if="isSelected"
              id="textarea-message"
              v-model="plainteObject.message"
              :placeholder="$t('complaints.complaint_details.modal_treatment.label_message_placeholder')"
              rows="3"
            />
          </b-card-text>
        </validation-observer>
        <hr>
        <div class="text-right">
          <b-button
            v-if="$can('treat', 'complaint')"
            variant="outline-secondary"
            class="mr-1"
            @click="hideModalTraitePlainte()"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>{{ $t('complaints.complaint_details.modal_treatment.button_cancel') }}</span>
          </b-button>
          <b-button
            v-if="$can('treat', 'complaint')"
            variant="primary"
            :disabled="isLoading"
            @click="treated()"
          >
            <b-spinner
              v-if="isLoading"
              small
              class="mr-25"
            />
            <feather-icon
              v-else
              icon="CheckIcon"
              class="mr-25"
            />
            <span>{{ $t('complaints.complaint_details.modal_treatment.button_accept') }}</span>
          </b-button>
        </div>
      </b-modal>
    </b-row>

  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BCardText,
  BButton,
  BModal,
  VBModal,
  BSpinner,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import localstorageService from '@/services/localstorage/localstorage.service'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import TreatmentTimeline from '@/components/TreatmentTimeline.vue'
import DownloadFile from '@/components/DownloadFile.vue'

// store modules and vuex utilities
// eslint-disable-next-line import/no-cycle
import plaintesStoreModules from '@/store/plaintes'
// eslint-disable-next-line import/no-cycle
import statusStoreModule from '@/store/status'

// eslint-disable-next-line import/no-cycle
import {
  registerStoreModule,
  // unregisterStoreModule,
} from '@/helpers/vuex-utils'

const modalTraitePlainteRef = 'modalTraitePlainteRef'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BCardText,
    BButton,
    BModal,
    vSelect,
    BSpinner,
    BFormCheckbox,
    TreatmentTimeline,
    DownloadFile,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      isSelected: false,
      detailsId: null,
      detailsData: null,
      listStatus: [],
      modalTraitePlainteRef,
      plainteObject: {
        object: null,
        observation: null,
        message: null,
      },
    }
  },

  computed: {
    shouldDisplayTreatmentCard() {
      if (this.detailsData && this.detailsData.statuses) {
        // Vérifiez chaque élément dans statuses
        const isNotTreatedOrInTreatment = this.detailsData.statuses.some(status => status.name === 'not_treated' || status.name === 'in_treatment')
        // Si le name correspond à "not_treated" ou "in_treatment", n'affichez pas le b-card
        return !isNotTreatedOrInTreatment
      }
      // Si aucun statut correspondant n'est trouvé, affichez le b-card
      return true
    },
  },

  setup() {
    const requiredStoreModules = [
      { path: 'plaintes', module: plaintesStoreModules },
      { path: 'status', module: statusStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },

  watch: {
    $route: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      async handler(val, old) {
        this.detailsId = localstorageService.getPlainteId()
        await this.loadStatus()
        await this.loadDedtailsData()
      },
    },
  },

  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules)
  // },

  methods: {
    ...mapActions('plaintes', {
      action_getPlainte: 'getPlainte',
      action_deletePlainte: 'deletePlainte',
      action_treatePlainte: 'treatePlainte',
    }),
    ...mapActions('status', {
      action_fetchStatus: 'fetchStatus',
    }),

    async loadDedtailsData() {
      try {
        const response = await this.action_getPlainte(this.detailsId)
        if (response.status === 200) {
          this.detailsData = response.data.data
        } else {
          console.log("La réponse n'a pas un status HTTP 200")
        }
      } catch (error) {
        console.log('Erreur lors du chargement des informations de la plainte', error)
        await this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Une erreur est survenue',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: '',
          },
        })
      }
    },

    showModalTraitePlainte() {
      this.$refs[modalTraitePlainteRef].show()
    },
    hideModalTraitePlainte() {
      this.$refs[modalTraitePlainteRef].hide()
    },

    traiterPlainte() {
      this.showModalTraitePlainte()
    },

    // Chargement des status
    async loadStatus() {
      try {
        const response = await this.action_fetchStatus()
        if (response.status === 200) {
          this.listStatus = response.data
        } else {
          console.log("La réponse n'a pas un status HTTP 200")
        }
      } catch (error) {
        console.log('Erreur lors du chargement de la liste des status', error)
        await this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Une erreur est survenue',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: '',
          },
        })
      }
    },

    async treated() {
      this.$refs.validationForm.validate().then(async success => {
        if (success) {
          this.isLoading = true
          const data = {
            label: this.plainteObject.object,
            observation: this.plainteObject.observation,
            message: this.plainteObject.message,
          }
          try {
            const response = await this.action_treatePlainte({ complaint: this.detailsId, data })
            if (response.status === 200) {
              this.isLoading = false
              await this.loadDedtailsData()
              this.hideModalTraitePlainte()
              await this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Traitement réussie',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            } else {
              console.log("La réponse n'a pas un status HTTP 200")
            }
          } catch (error) {
            this.isLoading = false
            console.log('Erreur lors du traitement de la plainte', error)
            await this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Une erreur est survenue',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: '',
              },
            })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Champs requis',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
    },

    resetModalData() {
    // Réinitialisez les données du formulaire/modal ici
      this.plainteObject = {
        object: '',
        observation: '',
        message: '',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .nav-pills .nav-link {
  color: white !important;
}
::v-deep .nav-pills .nav-link.active {
  color: #7367f0 !important;
}

.ombreBoite {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px;
}
</style>
